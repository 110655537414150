import React, { useEffect, useState } from "react"
import { Grid } from "@mui/material"
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel"
import moment from "moment"
import { TCSelect } from "components/TopCourtComponents/Input/TopCourtInput"

export const courtTypeObj = {
    all_court: "All Court",
    indoor: "Indoor",
    outdoor: "Outdoor",
    hitting_room: "Hitting Room"
}

function BookingScheduleList ({
    data,
    date,
    court,
    onChangeSchedule, // format { date: "", hours: []}
    disableSelectionClass,
    allowMultiple = false,
    allowMultipleCourt = false,
    checkTime = true,
    detailData
}) {

    const [ rerender, setRerender ] = useState(Date.now)
    const [ selectedForm, setSelectedForm ] = useState([])
    const hours = data ? data.hours : []
    // check jika dikurang atau ditambah > 1 atau < 1

    // tetap harus ambil pertanggal per court

    useEffect(() => {
        if (allowMultiple && !allowMultipleCourt) { if (data._id != court && data.date == date) { setSelectedForm([]) } }
        else {
            if (!allowMultiple) { if (data.date != date) { setSelectedForm([]) } }
            if (!allowMultipleCourt) { if (data._id != court) { setSelectedForm([]) } }
        }
    }, [date, court])

    const onChange = (e) => {
        setSelectedForm(e)
        if (!allowMultiple) { onChangeSchedule({
            date: data.date, hours: e
        }) } else if (!allowMultipleCourt) { onChangeSchedule({
            court , date: data.date, hours: e
        }) } else { onChangeSchedule({
            date: data.date, hours: e
        })}
        setRerender(Date.now)
    }

    const functionCheckHours = () => {
        if (disableSelectionClass) {
            let thereisClass = null
            let { currentClass, court, date } = disableSelectionClass

            for (let i = 0; i < currentClass.length; i ++) {
                if (currentClass[i].date == date) {
                    if (court == currentClass[i].court) { 
                        thereisClass = currentClass[i].hours; 
                        break; }
                }
            }
            
            return thereisClass
        }
        if (detailData) {
            // check current Data
            let { item, date, court } = detailData

            console.log('check item info', item)

            if (!item.userBookedSession || !item.serviceSession || !item.offSchedule) {
                return null
            }

            let isExist = false
            let hours = []
            
            for (let i = 0; i < item.userBookedSession.length; i ++) {
                if (item.userBookedSession[i].date == date 
                    // && item.userBookedSession[i].court == court
                ) {
                    hours = hours.concat(item.userBookedSession[i].hours)
                    isExist = true
                }
            }
            for (let i = 0; i < item.serviceSession.length; i ++) {
                if (item.serviceSession[i].date == date 
                    // && item.serviceSession[i].court == court
                ) {
                    hours = hours.concat(item.serviceSession[i].hours)
                    isExist = true
                }
            }
            for (let i = 0; i < item.scheduleOff.length; i ++) {
                if (item.scheduleOff[i].date == date) {
                    hours = hours.concat(item.scheduleOff[i].hours)
                    isExist = true
                }
            }

            if (isExist) { return hours }

        }
        return null
    }

    // convert to filter -- to do if current UI not good
    // const convertToOptions = () => {

    // }

    return (
        <Grid item xs={12}>
            {/* <TCSelect options={[
                {value: 'hello', label: 'world'}
            ]}/> */}
            {/*  `${courtTypeObj[data.court.courtType]} ${data.court.name} - ${moment(data.date).format("DD/MM/YYYY")} */}
            <TCLabel bold>{data ? `${courtTypeObj[data.courtType]} ${data.name}` : ""}</TCLabel>
            <HoursComponent form={selectedForm} onChange={onChange} availableHours={hours} 
                allowCheckTime={checkTime}
                disabledHours={functionCheckHours}
            />
        </Grid>
    )
}

function HoursComponent ({
    form,
    onChange,
    availableHours,
    allowCheckTime,
    disabledHours
}) {

    useEffect(() => {
    }, [form])

    const updateSelectedTime = (value) => {

        let index = form.indexOf(value);
        let newForm = form;
        
        if (value && index < 0) {
            newForm = [ ...form, value ];
        } else if (index >= 0) {
            newForm.splice(index, 1);
        }

        newForm = bubbleSort(newForm)
        if (allowCheckTime) {
            let isValid = checkSelectionTime(newForm)
            if (!isValid) { newForm = [] }
        }

        onChange(newForm)
    }

    return (
        <Grid container>
            {/* <Grid mb={1} item>
                <TCLabel bold>Time</TCLabel>
            </Grid> */}
            <Grid item container spacing={0.98}>
            {
                availableHours.map( value => {
                    return (
                        <Grid item>
                            <div className={
                                    (disabledHours() && disabledHours().indexOf(value) >= 0) ? 'selection-disabled' :
                                    form.indexOf(value) >= 0 ? 'selection-selected' : checkTime(form, value, allowCheckTime) ? 'selection-available' : 'selection-disabled'
                                } // selection-disabled 
                                style={{textAlign: 'center', padding: 5, minWidth: 25, borderRadius: 8}}
                                onClick={() => {
                                    if ((disabledHours() && disabledHours().indexOf(value) >= 0)) { return; }
                                    if (!checkTime(form, value, allowCheckTime)) { return; }
                                    updateSelectedTime(value)
                                }}
                            >
                                {/* <MKTypography variant="small" fontWeight="regular" color="inherit">{value.label}</MKTypography> */}
                                <div>{value}</div>
                            </div>
                        </Grid>
                    )
                })
            }
            </Grid>
        </Grid>
    )
}

function checkTime (
    hours, currentHours, allowCheckTime
) {
    let valid = true
    if (!allowCheckTime) { return true }
    if (hours.length > 0) {
        let currHoursNumber = Number(currentHours.split(":")[0])
        let firstHoursNumber = Number(hours[0].split(":")[0])
        let lastHoursNumber = Number(hours[hours.length - 1].split(":")[0])
        let check1 = Math.abs(firstHoursNumber - currHoursNumber)
        let check2 = Math.abs(lastHoursNumber - currHoursNumber)
        if (check1 > 1 && check2 > 1) { valid = false }
    }
    return valid
}

function checkSelectionTime (hours) {
    let valid = true
    console.log('here new check time',hours)
    for (let i = 0; i < hours.length; i ++) {
        if (i > 0) {
            let beforeHoursNumber = Number(hours[i - 1].split(":")[0])
            let hoursNumber = Number(hours[i].split(":")[0])
            console.log('here new check time', Math.abs(hoursNumber - beforeHoursNumber))
            if (Math.abs(hoursNumber - beforeHoursNumber) > 1) {
                valid = false
                break
            }
        }
    }
    return valid
}

function bubbleSort(arr) {
    var i, j;
    var len = arr.length;
    var isSwapped = false;
    for (i = 0; i < len; i++) {
        isSwapped = false;
        for (j = 0; j < (len - i - 1); j++) {
            if (arr[j] > arr[j + 1]) {
                var temp = arr[j]
                arr[j] = arr[j + 1];
                arr[j + 1] = temp;
                isSwapped = true;
            }
        }
        if (!isSwapped) {
            break;
        }
    }
    return arr
}

export { BookingScheduleList }
import React, { useState, useEffect } from "react";
import Lottie from "react-lottie";
// import loadingTennis from "../../../assets/lottiejson/tennis_loading.json";
import loadingTennis from "../../assets/lottiejson/tennis_loading.json"

export default function Loading({

}) {

    const [ isVisible, setIsVisible ] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setIsVisible(true)
        }, 100)
    }, [])

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingTennis,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <div style={{
            backgroundColor: '#1d4e8920',
            // backgroundColor: 'transparent',
            position: 'fixed',
            zIndex: 999999,
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            paddingTop: '30vh',
            backdropFilter: 'blur(5px)',
            display: isVisible ? '' : 'none'
        }}>
            <Lottie 
                options={defaultOptions}
                height={'60vh'}
                width={'50vh'}
            />
        </div>
    )
}
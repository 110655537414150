import React, { useEffect, useState } from 'react';

// material-ui
import { Box, Button, Card, Dialog, Grid, Typography } from '@mui/material';

// project imports

import { TCLabel } from 'components/TopCourtComponents/Label/TopCourtLabel';
import { currencyFormat } from 'helper/NumberFormat';
import TableComponent from 'components/TopCourtComponents/Table/TableComponent';
import { TypeSelectOption } from '../players/playerFilter';
import { useDebounce } from 'use-debounce';
import { getPaymentSummary, getPayments } from 'services/paymentService';
import moment from 'moment';
import PaymentFilter from './paymentFilter';
import PaymentSummmary from './paymentSummary';
import { orderType } from 'constant/constant';

const initFilter = {
    load: true,
    paymentDateStart: moment().startOf('month').format('YYYY-MM-DDT17:00:00.000'),
    paymentDateEnd: moment().set({ hour: 23, minute: 59, second: 59, millisecond: 59 }).toISOString(),
    paymentDateType: TypeSelectOption[0],
    provider: null,
    type: null,
    amountMin: null,
    amountMax: null,
    amountType: TypeSelectOption[0],
    imeiRepeated: '',
    referenceId: '',
    page: 0
}

export default function Payments({

}) {
    const [filter, setFilter] = useState(initFilter)
    const [debouncedFilter] = useDebounce(filter, 500)

    const [totalData, setTotalData] = useState(0)
    const [rows, setRows] = useState([])
    const [summary, setSummary] = useState({
        grandTotal: 0,
        totalVA: 0,
        totalSTCHO: {
            totalOrder: 0,
            valuePack: 0,
            booking: 0,
            class: 0,
            service: 0,
            rent: 0,
            buy: 0
        },
        totalQRIS: 0
    })

    useEffect(()=>{
        loadPayment()
        loadSummary()
    },[])

    useEffect(() => {
        (async () => {
            if (debouncedFilter.load == true) {
                console.log('ini cek filter', filter)
                loadPayment()   
                loadSummary()
            }
        })()
    }, [debouncedFilter])

    const onChangeFilter = (e) => {
        setFilter(prev => ({
            ...prev,
            ...e,
            page: 0
        }))
    }

    const onChangePage = (e) => {
        setFilter(prev => ({
            ...prev,
            ...e
        }))
    }

    const clearFilter = () => {
        setFilter(initFilter)
    }

    const loadPayment = async () => {
        try {
            let { data, error } = await getPayments({
                paymentDateStart: filter.paymentDateStart ? filter.paymentDateStart : '',
                paymentDateEnd: filter.paymentDateEnd ? filter.paymentDateEnd : '',
                paymentDateType: filter.paymentDateType ? filter.paymentDateType.value : '',
                provider: filter.provider ? filter.provider.value : '',
                type: filter.type ? filter.type.value : '',
                amountMin: filter.amountMin ? filter.amountMin : '',
                amountMax: filter.amountMax ? filter.amountMax : '',
                amountType: filter.amountType ? filter.amountType.value : '',
                imeiRepeated: filter.imeiRepeated ? filter.imeiRepeated : '',
                referenceId: filter.referenceId ? filter.referenceId : '',
                page: filter.page
            })
            if (error) throw error
            if (data) {
                setRows(data.order)
                setTotalData(data.totalData)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const loadSummary = async () => {
        try{
            let { data, error } = await getPaymentSummary({
                paymentDateStart: filter.paymentDateStart ? filter.paymentDateStart : '',
                paymentDateEnd: filter.paymentDateEnd ? filter.paymentDateEnd : '',
                paymentDateType: filter.paymentDateType ? filter.paymentDateType.value : '',
                provider: filter.provider ? filter.provider.value : '',
                type: filter.type ? filter.type.value : '',
                amountMin: filter.amountMin ? filter.amountMin : '',
                amountMax: filter.amountMax ? filter.amountMax : '',
                amountType: filter.amountType ? filter.amountType.value : '',
                imeiRepeated: filter.imeiRepeated ? filter.imeiRepeated : '',
                referenceId: filter.referenceId ? filter.referenceId : ''
            })
            if(error)throw error
            if(data){
                setSummary({
                    grandTotal: data.grandTotal,
                    totalSTCHO: data.totalSTCHO
                })
            }
        }catch(error){
            console.log(error)

        }
    }

    const valueCustomator = (row) => {
        return ({
            paymentDate: <TCLabel>{moment(row.createdDate).format("YYYY/MM/DD HH:mm:ss")}</TCLabel>,
            // referenceId: <TCLabel>{row.referenceId}</TCLabel>,
            orderId: <Grid><a href={`/orders/${row._id}`} style={{color: 'black'}}>{row.bookingId}</a></Grid>,
            user: <span className='span-link' onClick={() => { window.location.href = `/users/detail/${row.user._id}`}}>{row.user ? row.user.name : '-'}</span>,
            provider: <TCLabel>{(row.paymentType == "bank_transfer" || row.paymentType == "Bank Transfer") ? "STC-HO" : "-"}</TCLabel>,
            type: <TCLabel>{orderType(row.orderType)}</TCLabel>,
            amount: <TCLabel>{`${currencyFormat(row.total)}`}</TCLabel>,
        })
    }


    return (
        <Grid>
            <PaymentFilter filter={filter} onChangeFilter={onChangeFilter} clearFilter={clearFilter}/>
            <PaymentSummmary summary={summary}/>
            <TableComponent
                columns={[
                    { label: "Payment Date" },
                    { label: "Booking Id" },
                    { label: "User" },
                    { label: "Provider" },
                    { label: "Type" },
                    { label: "Amount" }
                ]}
                totalData={totalData}
                rows={rows}
                valueCustomator={valueCustomator}
                page={filter.page}
                pageChange={(e) => {
                    onChangePage({page: e})
                }}
            />
        </Grid>
    )
}
import React, { useEffect, useState } from "react"
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel"
import { Button, Dialog, Grid, Tooltip } from "@mui/material"
import { QrCode2 } from "@mui/icons-material";
import { currencyFormat } from 'helper/NumberFormat';
import TableComponent from "components/TopCourtComponents/Table/TableComponent";
import { TCInput } from "components/TopCourtComponents/Input/TopCourtInput";
import { courtTypeObj } from "views/pages/package/packageList";
import moment from "moment";
import QRPage from "./qrPage";
import { Info } from "@mui/icons-material";
import { sortInformation } from "helper/SortItem";
import { orderStatusMap } from "helper/OrderStatus";

function OrderInfo ({
    data
}) {

    const [ showQR, setShowQR ] = useState({
        open: false
    })

    useEffect(() => {
        // loadOrderDetail()
        console.log('here row', data)
    }, [data])

    const valueCustomator = (row) => {
        return ({
            // name: <span className='span-link' onClick={() => { window.location.href = `/addon/edit/${row._id}` }}>{row.name}</span>,
            // image: <img style={{ maxWidth: 100, maxHeight: 50 }} src={row.itemImages}></img>,
            // type: <TCLabel>{AddonTypeOption.map((v) => { if (v.value === row.type) return v.label })}</TCLabel>,
            // price: <TCLabel>{currencyFormat(row.price)}</TCLabel>,
            // stock: <TCLabel>{row.stock}</TCLabel>,
            // status: <Switch checked={row.isActive} />,
            court: `${courtTypeObj[row.court.courtType]}${row.court.name}`,
            date: `${moment(row.date).format("YYYY/MM/DD")}`,
            hours: <Grid container direction={'row'}>
            {
                row.hours.map((h, index) => {
                    return <TCLabel style={{marginRight: 3}}>{`${h} - ${timeStringFormat(Number(h.split(':')[0]) + 1)} 
                        ${index < (row.hours.length - 1) ? " | " : ""}`}</TCLabel>
                })
            }    
            </Grid>,
            total: `${row.hours.length} Sessions`,
            price: `${currencyFormat(row.total)}`,
            barcode: <Grid className="element element-1" xs={12} style={{
                cursor: row.isPaid ? 'pointer' : 'not-allowed'
            }} onClick={(e) => {
                e.stopPropagation()
                if (row.isPaid) {
                    setShowQR({
                        open: true,
                        courtInfo: {
                            ...row.court,
                            hours: row.hours,
                            date: row.date
                        },
                        data: row,
                        uniqueCode: row.uniqueCode,
                        value: row.uniqueCode
                    })
                }
            }}>
                <QrCode2 fontSize={'large'} style={ row.isPaid ? {} : {
                    color: 'gray'
                }}>qr_code</QrCode2>
            </Grid>
        })
    }

    const valueCustomatorItem = (row) => {
        return ({
            // name: <span className='span-link' onClick={() => { window.location.href = `/addon/edit/${row._id}` }}>{row.name}</span>,
            // image: <img style={{ maxWidth: 100, maxHeight: 50 }} src={row.itemImages}></img>,
            // type: <TCLabel>{AddonTypeOption.map((v) => { if (v.value === row.type) return v.label })}</TCLabel>,
            // price: <TCLabel>{currencyFormat(row.price)}</TCLabel>,
            // stock: <TCLabel>{row.stock}</TCLabel>,
            // status: <Switch checked={row.isActive} />,
            service:  <Grid container direction={'row'} spacing={1}>
                <Grid item><img src={row.productInfo.image} width={60} height={'auto'}/></Grid>
                <Grid item>
                    <Grid>{row.productInfo.category}</Grid>
                    <Grid>{row.productInfo.name}</Grid>
                    { (data && data.orderType === 'rent' && (row.rentStatus && row.rentStatus.length > 0)) ? <Grid sx={{
                            cursor: 'pointer'
                        }}>
                        <Tooltip title={
                            <Grid container>
                                {
                                    row.rentStatus.map( (value, index) => {
                                        return (
                                            <Grid item container>
                                                <Grid item>
                                                    <TCLabel style={{
                                                        fontSize: 10
                                                    }}>
                                                        {moment(value.date).format('DD-MM-YYYY HH:mm:ss')}
                                                    </TCLabel>
                                                </Grid>
                                                <Grid item ml={.5}>
                                                    <TCLabel style={{
                                                        fontSize: 10
                                                    }}>
                                                        { index == 0 && `Rent start by ${value.admin.name}`}
                                                        { index == 1 && `Rent completed by ${value.admin.name}`}
                                                    </TCLabel>
                                                </Grid>
                                                <Grid xs={12}>===============</Grid>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        }>
                            <div><Info sx={{fontSize: 12}}></Info></div>
                        </Tooltip>
                    </Grid> : <></>}
                    { (data && data.orderType === 'sell' && (data.rentStatus && data.rentStatus.length > 0)) ? <Grid sx={{
                            cursor: 'pointer'
                        }}>
                        <Tooltip title={
                            <Grid container>
                                {
                                    data.rentStatus.map( (value, index) => {
                                        return (
                                            <Grid item container>
                                                <Grid item>
                                                    <TCLabel style={{
                                                        fontSize: 10
                                                    }}>
                                                        {moment(value.date).format('DD-MM-YYYY HH:mm:ss')}
                                                    </TCLabel>
                                                </Grid>
                                                <Grid item ml={.5}>
                                                    <TCLabel style={{
                                                        fontSize: 10
                                                    }}>
                                                        { index == 0 && `Order pickup process by ${value.admin.name}`}
                                                    </TCLabel>
                                                </Grid>
                                                <Grid xs={12}>===============</Grid>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        }>
                            <div><Info sx={{fontSize: 12}}></Info></div>
                        </Tooltip>
                    </Grid> : <></>}
                </Grid>
            </Grid>,
            court: data && data.orderType === 'sell'? '-' : `${courtTypeObj[row.court.courtType]}${row.court.name}`,
            date: `${moment(row.date).format("YYYY/MM/DD")}`,
            hours: data && data.orderType === 'sell'? '-' : <Grid container direction={'row'}>
            {
                row.hours.map((h, index) => {
                    return <TCLabel style={{marginRight: 3}}>{`${h} - ${timeStringFormat(Number(h.split(':')[0]) + 1)} 
                        ${index < (row.hours.length - 1) ? " | " : ""}`}</TCLabel>
                })
            }    
            </Grid>,
            total: data && data.orderType === 'sell'? `${row.qty} Unit` : `${row.hours.length} Sessions`,
            price: data && data.orderType === 'sell'? `${currencyFormat(row.productInfo.price * row.qty)}` : `${currencyFormat(row.productInfo.price * row.hours.length)}`,
            barcode: <Grid className="element element-1" xs={12} style={{
                cursor: row.isPaid ? 'pointer' : 'not-allowed'
            }} onClick={(e) => {
                e.stopPropagation()
                let courtInformation = {}
                let hoursInformation = {}
                if (row.productInfo) {
                    courtInformation = row.court
                    hoursInformation = {
                        date: row.date,
                        hours: row.hours
                    }
                }
                if (data.orderType == "sell") {
                    hoursInformation = {
                        date: data.createdDate
                    }
                }
                console.log('here qrpage', courtInformation, hoursInformation)
                if (data.isPaid && orderStatusMap(data) != "Completed") {
                    setShowQR({
                        open: true,
                        courtInfo: {
                            ...courtInformation,
                            ...data.court,
                            ...data.classObj.courtInfo,
                            hours: data.classObj.hours,
                            date: data.date
                        },
                        data: {
                            ...data,
                            eventName: data.classObj.name,
                            date: data.classObj.date,
                            hours: data.classObj.hours,
                            ...hoursInformation,
                        },
                        uniqueCode: data.uniqueCode,
                        value: data.uniqueCode
                    })
                } 
            }}>
                <QrCode2 fontSize={'large'} style={ row.isPaid ? {} : {
                    color: 'gray'
                }}></QrCode2>
            </Grid>
        })
    }

    const valueCustomatorPackages = (row) => {
        console.log(row)
        return({
            package: row.packageName,
            court: courtTypeObj[row.court],
            sessions: `${courtTypeObj[row.court]} ${row.packageSession}`,
            advancedBooking: `Month + ${row.advancedBooking}`,
            sessionValidity: `${row.sessionValidity} Month`,
            subtotal: currencyFormat(data.subtotal),
            total: currencyFormat(data.subtotal),
        })
    }

    const valueCustomatorClass = (row) => {
        if (data.classSession && data.classSession.session > 0) {
            return({
                className: <a href={`/classes/edit/${data.classId._id}`} style={{
                    color: 'black'
                }}>{data.classObj.name}</a>,
                court: `${data.court ? `${courtTypeObj[data.court.courtType]} ${data.court.name}` : '-'}`,
                sessions: `${data.classSession.session}`,
                pax: `${data.classSession.qty}`,
                subtotal: currencyFormat(data.subtotal),
                total: currencyFormat(data.subtotal),
                barcode: ''
            })
        } else {
            let coachInfo = {}
            if ((data.classLists && data.classLists.length > 0) && (row.service && row.service.length > 0)) {
                console.log('service info', row)
                let devCoach = [] // Development Coach
                let leadCoach = [] // Lead Coach
                let seniorCoach = [] // Senior Coach
                let headCoach = [] // Head Coach

                for (let serviceIndex = 0; serviceIndex < row.service.length; serviceIndex ++) {
                    let svc = row.service[serviceIndex]
                    if (svc.category && svc.category.name == 'Head Coach') {
                        headCoach.push({
                            _id: svc._id,
                            name: `${svc.name}`
                        })
                    }
                    if (svc.category && svc.category.name == 'Senior Coach') {
                        seniorCoach.push({
                            _id: svc._id,
                            name: `${svc.name}`
                        })
                    }
                    if (svc.category && svc.category.name == 'Lead Coach') {
                        leadCoach.push({
                            _id: svc._id,
                            name: `${svc.name}`
                        })
                    }
                    if (svc.category && svc.category.name == 'Development Coach') {
                        devCoach.push({
                            _id: svc._id,
                            name: `${svc.name}`
                        })
                    }
                }

                coachInfo = {
                    coachInfo: <Grid>
                        {headCoach.map( (value, index) => {
                            return (
                                <a href={`/addon/edit/${value._id}`} style={{
                                    color: 'black'
                                }}>{value.name}{index == headCoach.length - 1 ? "" : ", "}</a>
                            )
                        })}
                        {seniorCoach.map( (value, index) => {
                            return (
                                <a href={`/addon/edit/${value._id}`} style={{
                                    color: 'black'
                                }}>{value.name}{index == seniorCoach.length - 1 ? "" : ", "}</a>
                            )
                        })}
                        {leadCoach.map( (value, index) => {
                            return (
                                <a href={`/addon/edit/${value._id}`} style={{
                                    color: 'black'
                                }}>{value.name}{index == leadCoach.length - 1 ? "" : ", "}</a>
                            )
                        })}
                        {devCoach.map( (value, index) => {
                            return (
                                <a href={`/addon/edit/${value._id}`} style={{
                                    color: 'black'
                                }}>{value.name}{index == devCoach.length - 1 ? "" : ", "}</a>
                            )
                        })}
                    </Grid>
                }
            }
            if (!row.service || row.service.length == 0) { coachInfo = {
                coachInfo: <Grid>{"-"}</Grid>
            }}
            console.log('here data Info', data)
            let hoursPlus1 = Number(row.hours[row.hours.length - 1].split(':')[0]) + 1
            return({
                className: <a href={`/classes/edit/${data.classId._id}`} style={{
                    color: 'black'
                }}>{row.name}</a>,
                ...coachInfo,
                court: `${courtTypeObj[row.courtInfo.courtType]} ${row.courtInfo.name}`,
                date: `${moment(row.date).format('YYYY/MM/DD')}`,
                sessions: `${row.hours[0]} - ${
                    hoursPlus1 < 9 ? `0${hoursPlus1}:00` : `${hoursPlus1}:00`
                }`,
                pax: `${row.pax}`,
                subtotal: currencyFormat(row.fee),
                total: currencyFormat(data.subtotal),
                barcode: <Grid className="element element-1" xs={12} style={{
                    cursor: data.isPaid ? 'pointer' : 'not-allowed'
                }} onClick={(e) => {
                    e.stopPropagation()
                    if (data.isPaid) {
                        setShowQR({
                            open: true,
                            courtInfo: {
                                ...row.courtInfo,
                                hours: row.hours,
                                date: row.date
                            },
                            data: {
                                ...data,
                                ...row
                            },
                            uniqueCode: data.uniqueCode,
                            value: data.uniqueCode
                        })
                    }
                }}>
                    <QrCode2 fontSize={'large'} style={ data.isPaid ? {} : {
                        color: 'gray'
                    }}>qr_code</QrCode2>
                </Grid>
            })
        }
    }

    return (
        <Grid>
            <Grid container spacing={4} mb={2}>
                <Grid item>
                    <TCLabel bold>Total Order</TCLabel>
                    <TCLabel>{currencyFormat(data.subtotal)}</TCLabel>
                </Grid>
                <Grid item>
                    <TCLabel bold>Additional Fee</TCLabel>
                    <TCLabel>{currencyFormat(data.additionalFee || 0)}</TCLabel>
                </Grid>
                <Grid item>
                    <TCLabel bold>Discount</TCLabel>
                    {/* {console.log('here promocodeinfo', data.promoCodeInfo)}
                    {console.log('here sessioninfo', data)} */}
                    <TCLabel>{(data.promoCodeInfo && data.promoCodeInfo.promoCode) ? 
                        `- ${data.promoCodeInfo.discountType == 'percentage' ? 
                        `${data.promoCodeInfo.amount}%` : currencyFormat(data.promoCodeInfo.amount)} (${data.promoCodeInfo.promoCode})` :
                        data.promoCode ? currencyFormat(data.discount) : ''}</TCLabel>
                    <TCLabel>{(data.totalSessionRedeemed && data.totalSessionRedeemed > 0) ? 
                        <>
                        <div>
                        {
                            `${data.sessionRedeemed && data.sessionRedeemed.all_court ? `${data.sessionRedeemed.all_court} All Court (Value pack)` : ''}`
                        }
                        </div>
                        <div>
                        {
                            `${data.sessionRedeemed && data.sessionRedeemed.outdoor ? `${data.sessionRedeemed.outdoor} Outdoor (Value pack)` : ''}`
                        }
                        </div>
                        <div>
                        {
                            `${data.sessionRedeemed && data.sessionRedeemed.hitting_room ? `${data.sessionRedeemed.hitting_room} Hitting (Value pack)` : ''}`
                        }
                        </div>
                        </>
                        : ''}
                    </TCLabel>
                    <TCLabel>{data.adminDiscount ? `- ${currencyFormat(data.adminDiscount)}` : ''}</TCLabel>
                    <TCLabel>{(data.classSessionRedeemed && data.classSessionRedeemed > 0) ? `${data.classSessionRedeemed} session${data.classSessionRedeemed > 1 ? 's' : ''} (Class Bundle)` : ''}</TCLabel>
                    <TCLabel>
                        {
                            ((!data.totalSessionRedeemed || data.totalSessionRedeemed < 0) 
                                && (!data.promoCodeInfo || (data.promoCodeInfo && !data.promoCodeInfo.promoCode))
                                && (!data.adminDiscount || (data.adminDiscount && data.adminDiscount == 0))
                                && (!data.classSessionRedeemed || data.classSessionRedeemed < 0)
                            ) && '-'
                        }
                    </TCLabel>
                </Grid>
                {/* <Grid item>
                    <TCLabel subtitle>Session</TCLabel>
                    <TCLabel>{currencyFormat(data.subtotal)}</TCLabel>
                </Grid> */}
                <Grid item>
                    <TCLabel bold>Unique Code</TCLabel>
                    <TCLabel>{data.uniqueNumber ? data.uniqueNumber : "-"}</TCLabel>
                </Grid>
                <Grid item>
                    <TCLabel bold>Total Payment</TCLabel>
                    <TCLabel>{currencyFormat(data.total)}</TCLabel>
                </Grid>
            </Grid>
            {
                (data.sessions && data.sessions.length > 0) &&
                <TableComponent
                    columns={[
                        { label: "Court" },
                        { label: "Date" },
                        { label: "Hours" },
                        { label: "Total" },
                        // { label: "Price" },
                        { label: "Sub Total" },
                        { label: "" },
                    ]}
                    rows={(data && data.sessions) ? data.sessions : []}
                    valueCustomator={valueCustomator}
                />
            }
            {
                (data.items && data.items.length > 0) &&
                <TableComponent
                    columns={[
                        { label: "Service" },
                        { label: "Court" },
                        { label: "Date" },
                        { label: "Hours" },
                        { label: "Total" },
                        // { label: "Price" },
                        { label: "Sub Total" },
                        { label:  "" }
                    ]}
                    rows={(data && data.items) ? sortInformation(data.items) : []}
                    valueCustomator={valueCustomatorItem}
                />
            }
            {
                (data.packages && data.packages.length > 0) &&
                <TableComponent
                    columns={[
                        { label: "Package" },
                        { label: "Court" },
                        { label: "Sessions" },
                        { label: "Advanced Booking" },
                        { label: "Session Validity" },
                        { label: "Total" },
                        // { label: "Price" },
                        { label: "Sub Total" },
                    ]}
                    rows={(data && data.packages) ? data.packages : []}
                    valueCustomator={valueCustomatorPackages}
                />
            }
            {
                (data.classObj && data.classObj.hours.length > 0) &&
                <TableComponent
                    columns={[
                        { label: "Event Name" },
                        { label: "Court" },
                        { label: "Date" },
                        { label: "Sessions" },
                        { label: "Pax" },
                        { label: "Total" },
                        // { label: "Price" },
                        { label: "Sub Total" },
                        { label: "" },
                    ]}
                    rows={[data.classObj]}
                    valueCustomator={valueCustomatorClass}
                />
            }
            {
                (data.classLists && data.classLists.length > 0) &&
                <TableComponent
                    columns={[
                        { label: "Class Name" },
                        { label: "Coach" },
                        { label: "Court" },
                        { label: "Date" },
                        { label: "Sessions" },
                        { label: "Pax" },
                        { label: "Total" },
                        // { label: "Price" },
                        { label: "Sub Total" },
                        { label: "" },
                    ]}
                    rows={data.classLists}
                    valueCustomator={valueCustomatorClass}
                />
            }
            {
                (data.classSession && data.classSession.session > 0) &&   
                <TableComponent
                    columns={[
                        { label: "Class Name" },
                        { label: "Court" },
                        { label: "Sessions" },
                        { label: "Qty" },
                        { label: "Total" },
                        // { label: "Price" },
                        { label: "Sub Total" },
                        { label: "" },
                    ]}
                    rows={[data.classSession]}
                    valueCustomator={valueCustomatorClass}
                />
            }
            {
                showQR &&
                <Dialog open={showQR.open} maxWidth="xs" 
                    onClose={() => {
                        setShowQR(prev => ({
                            ...prev,
                            open: false
                        }))
                    }}
                    sx={{
                        "& .MuiDialog-container": {
                          "& .MuiPaper-root": {
                            maxWidth: "360px",  // Set your width here
                          },
                        },
                    }}
                >
                    <Grid mt={2} mb={2}>
                        <QRPage courtInfo={showQR.courtInfo} qrValue={showQR.uniqueCode} orderInfo={showQR.data} />
                    </Grid>
                </Dialog>
            }
        </Grid>
    )
}

const timeStringFormat = (e) => {
    let value = e
    return `${value < 10 ? `0${value}` : value}:00`
}

export default OrderInfo
import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";
// import { Delete, Get, Patch, Post, Put } from "./RestClient.js";
import { FormatServerDate, client, errorValidation } from "./service.js";

export async function getClasses({
    className, eventDateType, eventDateStart, eventDateEnd, ageType, 
    ageMin, ageMax, priceType, priceMin, priceMax, user
}) {
    try {
        let places = await axios.Get({
            url: `${adminUrl}/class-list`,
            params: {
                className: className || '',
                eventDateType: eventDateType || '',
                eventDateStart: FormatServerDate(eventDateStart) || '',
                eventDateEnd: eventDateEnd || '',
                ageType: ageType || '',
                ageMin: ageMin || '',
                ageMax: ageMax || '',
                priceType: priceType || '',
                priceMin: priceMin || '',
                priceMax: priceMax || '',
                user: user || ''
            }
        });

        if (places.statusCode !== 200) {
            return { error: places.message };
        }

        return { data: places.data };
    } catch (e) {
        return { error: e.response.data };
    }
}

export async function getClassesv2({
    className, eventDateType, eventDateStart, eventDateEnd, ageType, 
    ageMin, ageMax, priceType, priceMin, priceMax, user, page, limit
}) {
    try {
        let places = await axios.Get({
            url: `${adminUrl}/class-list-v2`,
            params: {
                className: className || '',
                eventDateType: eventDateType || '',
                eventDateStart: eventDateStart || '',
                eventDateEnd: eventDateEnd || '',
                ageType: ageType || '',
                ageMin: ageMin || '',
                ageMax: ageMax || '',
                priceType: priceType || '',
                priceMin: priceMin || '',
                priceMax: priceMax || '',
                user: user || '',
                page: page || 0,
                limit: limit || 20
            }
        });

        if (places.statusCode !== 200) {
            return { error: places.message };
        }

        return { data: places.data };
    } catch (e) {
        return { error: e.response.data };
    }
}

export async function getClassDetail(classId) {
    try {
        let response = await client.get(`/class/${classId}`);
        console.log('here check data', response)
        if (response.data.statusCode !== 200) { throw response.data.data; }
        let data = response.data.data;
        return { data };
    } catch (error) {
        console.log('here check data', error)
        return errorValidation(error)
    }
}

export async function loadBookingforClasses() {
    try {
        let response = await client.get(`/class-order`);
        if (response.data.statusCode !== 200) { throw response.data.data; }
        let data = response.data.data;
        return { data };
    } catch (e) {
        // return { error: e.response.data.errorCode };
        return errorValidation(e)
    }
}

export async function getClassParticipant (classId) {
    try {
        let response = await client.get(`/class-participant/${classId}`);
        if (response.data.statusCode !== 200) { throw response.data.data; }
        let data = response.data.data;
        return { data };
    } catch (e) {
        // return { error: e.response.data.errorCode };
        return errorValidation(e)
    }
}

export async function createClass(body) {
    try {
        let response = await client.post(`/class-create`, {
            ...body
        });
        if (response.data.statusCode !== 200) { throw response.data.data; }
        let data = response.data.data;
        return { data };
    } catch (e) {
        // return { error: e.response.data.errorCode };
        return errorValidation(e)
    }
}

export async function updateClass(body) {
    try {
        let response = await client.post(`/class-update`, {
            ...body
        });
        if (response.data.statusCode !== 200) { throw response.data.data; }
        let data = response.data.data;
        return { data };
    } catch (e) {
        // return { error: e.response.data.errorCode };
        return errorValidation(e)
    }
}

export async function updateClassStatus(body) {
    try {
        let response = await client.post(`/class-active`, {
            ...body
        });
        if (response.data.statusCode !== 200) { throw response.data.data; }
        let data = response.data.data;
        return { data };
    } catch (e) {
        // return { error: e.response.data.errorCode };
        return errorValidation(e)
    }
}

// create Class
// crate orderSession
import React, { useEffect, useState } from 'react';

// material-ui
import { Box, Button, Card, Grid, FormControlLabel, Checkbox, Switch } from '@mui/material';

import { TCInput, TCSelect } from 'components/TopCourtComponents/Input/TopCourtInput';
import { TCLabel, TCTitleLabel } from 'components/TopCourtComponents/Label/TopCourtLabel';

import { NumericFormat } from 'react-number-format';
import ReactQuill from 'react-quill';
import DropZoneComponent from 'components/DropzoneComponent';

import { createClass, getClassDetail, getClassParticipant, getClasses, loadBookingforClasses, updateClass } from 'services/classService';
import TCAccordion from 'components/TopCourtComponents/TCAccordion';
import moment from 'moment';
import { BookingScheduleList, courtTypeObj } from './schedule';
import { handleUpload } from 'helper/S3';
import { Confirm } from 'helper/Alert';
import { getParamsFromURL } from 'helper/Params';
import { validation } from 'helper/Form';
import { useNavigate } from 'react-router';
import { getPlayers } from 'services/playerServices';
import AsyncSelect from 'react-select/async';
import ClassScheduleInfo from './classScheduleInfo';
import DateRangeInput from 'components/TopCourtComponents/Input/DateRangeFilter';

function AddClass ({

}) {

  var toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
  ];

  var typeOptions = [
    { value: "event", label: "Event" },
    { value: "class", label: "Class" }
  ]

  const [ participant, setParticipant ] = useState([])

  const [ form, setForm ] = useState({
    classImage: null,
    classImages: [],
    name: "",
    date: "",
    court: null,
    hours: [],
    playersCapacity: "",
    minAge: "",
    maxAge: "",
    fee: "",
    gender: ["male", "female"],
    feeNominal: null,
    description: "",
    paymentMethod: "direct",
    courts: [],
    courtsInfo: [],
    coach: [],
    hideUsername: false,
    isActive: true,
    // Information - for fixed and dynamic
    classType: typeOptions[1], // event or class
    courtSelection: null, // for fixed and dynamic
    courtType: null,
    startClassDate: null,
    endClassDate: null,
    offDays: null,
    classStartHours: null,
    classEndHours: null,
    bundleSession: null,
    bundlePrice: null,
    bundleEndDate: null,
    dates: [],
    coachInfo: null
  })

  const [ joiForm, setJoiForm ] = useState({
    name: null,
    date: null,
    court: null,
    hours: null,
    playersCapacity: null,
    minAge: null,
    maxAge: null,
    fee: null,
    courtType: null,
    court: null,
    startClassDate: null,
    endClassDate: null,
    classStartHours: null,
    classEndHours: null
  })

  const [ bookingList, setBookingList ] = useState([])
  const [ currentClass, setCurrentClass ] = useState([])
  const [ loading, setLoading ] = useState(false)

  useEffect(() => {
    setJoiForm({
        name: form.name ? null : "*",
        date: form.date ? null : "*",
        court: form.court ? null : "*",
        hours: form.hours && form.hours.length > 0 ? null : "*",
        playersCapacity: form.playersCapacity ? null : "*",
        minAge: !form.minAge || (form.minAge && Number(form.minAge) > 0) ? null : "*",
        maxAge: (!form.minAge || !form.maxAge) || ((form.minAge && form.maxAge) && (form.minAge <= form.maxAge)) ? null : "*",
        fee: form.fee ? null : "*",
        description: removeTags(form.description) <= 500 ? null : "*",
        // 
        courtType: form.classType.value == typeOptions[1].value ? form.courtType ? null : '*' : '*',
        startClassDate: form.classType.value == typeOptions[1].value ? form.startClassDate ? null : '*' : '*',
        endClassDate: form.classType.value == typeOptions[1].value ? form.endClassDate ? null : '*' : '*',
        classStartHours: form.classType.value == typeOptions[1].value ? form.classStartHours ? null : '*' : '*',
        classEndHours: form.classType.value == typeOptions[1].value ? form.classEndHours ? null : '*' : '*',
    })
  }, [form])

  useEffect(() => {
    loadClassInfo()
  }, [])

  const onChange = (e) => {
    setForm(prev => ({
      ...prev,
      ...e
    }))
  }

  const loadUserOption = async (input) => {
    if (input.length < 3) {
        return
    }
    try {
        let { data, error } = await getPlayers({
            name: input
        });
        if (error) throw error
        if (data) {
            if (data.length <= 0) return
            return data.map(value => {
                return {
                    label: value.name,
                    value: value._id
                }
            })
        }
    } catch (error) {
        console.log(error.message || error)
        return ([])
    }
  }

  const removeTags =(str) => {
    if ((str === null) || (str === ''))
        return 0;
    else
        str = str.toString(); 
    let stringWithoutTags = str.replace(/<br>/g, ' ');
    let stringWithoutLineBreaks = stringWithoutTags.replace(/(<([^>]+)>)/ig, ''); // Replace line breaks with '\n'
    return stringWithoutLineBreaks.length;
}

  const loadClassInfo = () => {
    // check Id
    let { params } = getParamsFromURL(window);
    if (params) loadClassDetail(params)
    else { loadClassBooking() }
  }

  const loadClassDetail = async (classId) => {
    let {data, error} = await getClassDetail(classId)
    if (data) {
        let classSchedule = {}
        if (data.classSchedule) {
            // typeOptions
            let selectedType = typeOptions[0]
            typeOptions.map(value => {
                if (value.value == data.classSchedule.classType) {
                    selectedType = value
                }
            })
            let courtType = []
            for (let i = 0; i < courtTypeObj.length; i ++) {
                if (data.classSchedule.courtType(courtTypeObj[i].value) >= 0) {
                    courtType.push(courtTypeObj[i])
                }
            }
            classSchedule = {
                classType: selectedType, // event or class
                courtSelection: data.classSchedule.courtSelection, // for fixed and dynamic
                courtType: courtType,
                startClassDate: data.classSchedule.startClassDate,
                endClassDate: data.classSchedule.endClassDate,
                classStartHours: data.hours[0],
                classEndHours: data.hours[data.hours.length - 1],
                dates: [],
                coaches: data.classSchedule.coaches
            }
        }
        setForm(prev => ({
            ...prev,
            ...data,
            dateTime: moment(data.date).format('YYYY-MM-DDT16:59:59.059'),
            gender: data.gender == "all" ? ["male", "female"] : [data.gender],
            classImage: data.classImages,
            coach: data.coach ? data.coach.map( value => { return ({label: value.name, value: value._id, data: value })}) : null,
            classImages: [],
            ...classSchedule,
            classType: data.classSchedule ? typeOptions[1] : typeOptions[0],
            bundleSession: data.classSchedule ? data.classSchedule.bundle ? data.classSchedule.bundle.bundleSession : 0 : null,
            bundleEndDate: data.classSchedule ? data.classSchedule.bundle ? data.classSchedule.bundle.bundleEndDate : 0 : null,
            bundlePrice: data.classSchedule ? data.classSchedule.bundle ? data.classSchedule.bundle.bundlePrice : 0 : null,
            coaches: data.classSchedule ? data.classSchedule.coaches ? data.classSchedule.coaches.map( value => { return ({label: value.name, value: value._id, data: value })}) : null : null,
        }))
        // load participant
        let participant = await getClassParticipant(data._id)
        if (participant.error) { console.log('error') }
        if (participant.data) {
            setParticipant(participant.data)
        }
    }
  }

  const disabledForm = () => {
    console.log('joiForm', joiForm)
    if (form.classType.value == typeOptions[0].value) {
        return joiForm.name || joiForm.date || joiForm.hours || joiForm.playersCapacity ||
        joiForm.minAge || joiForm.maxAge || joiForm.fee || joiForm.description
    } else {
        if (form.courtSelection == "fixed") {
            console.log(joiForm)
            return joiForm.name || joiForm.court || joiForm.courtType || joiForm.startClassDate ||  
            joiForm.endClassDate || joiForm.hours || joiForm.playersCapacity ||
            joiForm.minAge || joiForm.maxAge || joiForm.fee || joiForm.description
        } else {
            return joiForm.name || joiForm.courtType || joiForm.startClassDate ||  
            joiForm.endClassDate || joiForm.hours || joiForm.playersCapacity ||
            joiForm.minAge || joiForm.maxAge || joiForm.fee || joiForm.description
        }
    }
  }

  const submitClass = async () => {
    setLoading(true)
    let newBody = {
        ...form,
        fee: form.feeNominal,
        gender: form.gender.length > 1 ? "all" : form.gender[0],
        classImages: []
    }

    if (form.classImage) {
        for (let i = 0; i < form.classImage.length; i ++) {
            // check if class image is string
            if (typeof form.classImage[i] !== 'string'  ) {
                let imageUrl = await handleUpload(form.classImage[i])
                newBody.classImages.push(imageUrl[0])
            } else {
                newBody.classImages.push(form.classImage[i])
            }
        }
    }

    if (form.classType) {
        if (form.classType.value == typeOptions[0].value) { newBody.classType = form.classType ? form.classType.value : null }
        if (form.classType.value == typeOptions[1].value) {
            newBody.classType = form.classType ? form.classType.value : null
            // newBody.courtSelection = , // for fixed and dynamic
            newBody.courtType = form.courtType ? form.courtType.map(v => {return v.value;}) : null;
            newBody.court = form.court ? form.court.value : null;
            newBody.hours = convertHourstoArray({
                startHours: form.classStartHours,
                endHours: form.classEndHours
            })
            newBody.offDays = form.offDays ? form.offDays.map(value => {
                return value.value
            }) : null
            newBody.dates = getDates(form.startClassDate, form.endClassDate, newBody.offDays)
            // classStartHours: null,
            // classEndHours: null
        }
    }

    if (form.coach) {
        newBody.coach = form.coach.map(value => { return value.value })
    }

    console.log('here newBody', newBody)

    if (newBody._id) {
        let { data, error } = await updateClass(newBody);
        if (data) { Confirm("", `${form.classType ? form.classType.value == "class" ? "Class" : "Event" : "Event"} updated`); window.location.replace(`/classes/edit/${data._id}`) }
        setLoading(false)
    } else {
        let { data, error } = await createClass(newBody);
        if (data) { Confirm("", `${form.classType ? form.classType.value == "class" ? "Class" : "Event" : "Event"} created`); window.location.replace(`/classes/edit/${data._id}`)}
        setLoading(false)
    }

  }
  
  const loadClassBooking = async () => {
    try {
        setLoading(true)
        let { data, error } = await loadBookingforClasses()
        console.log('ini cek data',data)
        if (data) {
            let newBookings = []
            console.log(data.orders)
            newBookings = groupSession(data.orders)
            setBookingList(newBookings)
            setCurrentClass(data.classOrder)
        }
        setLoading(false)
    } catch (error) {
        setLoading(false)
        console.log(error)
    }
  }
  
  const deleteClassImage = (index) => {
    let currImages = form.classImage
    currImages.splice(index, 1);
    onChange({ classImage: currImages })
  }

  const checkCourtInfo = (e, v) => {
    console.log(e,v)

    // check date
    if (form.date == e.date) {
        let courts = form.courts
        let courtsInfo = form.courtsInfo
        if (courts.length > 0) {
            if (courts.indexOf(v._id) < 0) {
                courts.push(v._id)
                courtsInfo.push({
                    name: v.name,
                    courtType: v.courtType,
                    hours: e.hours
                })
            } else {
                courtsInfo[courts.indexOf(v._id)].hours = e.hours
            }
        } else {
            courts = [v._id]
            courtsInfo = [{
                name: v.name,
                courtType: v.courtType,
                hours: e.hours
            }]
        }
        onChange({
            courtInfo: {
                name: v.name,
                courtType: v.courtType,
            },
            court: v._id,
            date: e.date,
            hours: e.hours,
            courts,
            courtsInfo
        })
    } else {
        onChange({
            courtInfo: {
                name: v.name,
                courtType: v.courtType,
            },
            court: v._id,
            date: e.date,
            hours: e.hours,
            courts: [v._id],
            courtsInfo: [{
                name: v.name,
                courtType: v.courtType,
                hours: e.hours
            }]
        })
    }
  }

  const countTotal = (v, data) => {
    let total = 0
    for (let i = 0; i < data.length; i ++) {
        console.log('totalData-info', data)
        if (v == data[i].date) { total += data[i].classObj.pax }
    }
    return total
  }


  return (
    <>
        <Grid container spacing={2}>
            <Grid item container xs={12} md={8} lg={6}>
                <Card sx={{ p: 2, pl: 0, pr:0 }}>
                    <Box sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    <>
                        <Grid container p={2} pt={0} rowSpacing={1} style={{overflow: 'auto'}}>
                            <Grid item xs={12}>
                                <TCInput showAlert={joiForm.name} 
                                // disabled={form._id}
                                label={"Title"} value={form.name} onChange={(e) => { 
                                    if (e.currentTarget.value.length >= 50) { return; }
                                    onChange({name: e.currentTarget.value.trimStart()}) 
                                }}/>
                            </Grid>
                            <Grid item xs={12}>
                                <TCLabel style={{
                                    color: 'rgb(133, 133, 133)'
                                }}>Type</TCLabel>
                                <TCSelect options={typeOptions}
                                    disabled={form._id}
                                    value={form.classType}
                                    onChange={(e) => {
                                        onChange({ 
                                            classType: e, 
                                            courtType: null,
                                            court: null,
                                            startClassDate: null,
                                            endClassDate: null,
                                            classStartHours: null,
                                            classEndHours: null,
                                            hours: []
                                        })
                                    }}
                                />
                            </Grid>
                            {
                                form._id &&
                                <>
                                    <ClassSession data={form}/>
                                    <Grid item xs={12} mt={1} sx={{
                                        border: '1px solid #85858533',
                                        borderRadius: 2,
                                        padding: 1
                                    }}>
                                        {
                                            (form.classType.value == typeOptions[1].value) &&
                                            <Grid item container xs={12}>
                                                <ClassScheduleInfo 
                                                    classId={form._id}
                                                    formData={form}
                                                    setClassCourt={(e) => {
                                                        console.log('here setClassCourt', e)
                                                        setForm(prev => ({
                                                            ...prev,
                                                            ...e,
                                                            hours: convertHourstoArray({ startHours: e.classStartHours || null, endHours: e.classEndHours || null })
                                                        }))
                                                    }}
                                                />
                                                <Grid container spacing={1}>
                                                    <Grid item>
                                                        <Grid item container spacing={1}>
                                                            <Grid item>
                                                                <TCLabel style={{
                                                                    color: 'rgb(133, 133, 133)'
                                                                }}>Bundle: Total Price (in IDR)</TCLabel>
                                                            </Grid>
                                                            <Grid item>
                                                                <TCLabel style={{color: 'red'}}>{joiForm.bundlePrice}</TCLabel>
                                                            </Grid>
                                                        </Grid>
                                                        <NumericFormat value={form.bundlePrice}
                                                            onValueChange={(values) => {
                                                                const {formattedValue, value, floatValue} = values;
                                                                // do something with floatValue
                                                                onChange({ bundlePrice: value.replace(/^0+/, '') })
                                                            }}
                                                            // disabled={form._id}
                                                            decimalScale={0}
                                                            allowLeadingZeros={false}
                                                            allowedDecimalSeparators={false}
                                                            allowNegative={false}
                                                            thousandSeparator
                                                            customInput={TCInput}/>
                                                    </Grid>
                                                    <Grid item>
                                                        <TCInput xs label={"Bundle: Total Session"}
                                                            onChange={(e) => {
                                                                if (!validation.isNumber(e.currentTarget.value)) { return; }
                                                                if (e.currentTarget.value == "0") { return; }
                                                                if (e.currentTarget.value > 100) { return; }
                                                                onChange({
                                                                    bundleSession: e.currentTarget.value
                                                                })
                                                            }}
                                                            value={form.bundleSession}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid item container spacing={1}>
                                                            <Grid item>
                                                                {/* <TCLabel style={{
                                                                    color: 'rgb(133, 133, 133)'
                                                                }}>Bundle: End Date</TCLabel> */}
                                                                {/* <TCInput type={"date"} onChange={(e) => {
                                                                    onChange({
                                                                        bundleEndDate: e.currentTarget.value
                                                                    })                                                                
                                                                }} value={form.bundleEndDate}/> */}
                                                                <DateRangeInput 
                                                                    label={'Bundle: End Date'}
                                                                    disabledStart={!form.startClassDate}
                                                                    startDateMinDate={form.startClassDate}
                                                                    startDateMaxDate={form.endClassDate}
                                                                    placeholderStart={'End Date'}
                                                                    disabledRange={true}
                                                                    showLabel={true}
                                                                    startDate={form.bundleEndDate}
                                                                    onChangeStartDate={(e) => onChange({
                                                                        bundleEndDate: e
                                                                    })}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        }
                                    </Grid>
                                </>
                            }
                            {
                                !form._id &&
                                <Grid item container xs={12} mt={1} sx={{
                                    border: '1px solid #85858533',
                                    borderRadius: 2
                                }}>
                                    {
                                        (form.classType.value == typeOptions[0].value) &&
                                        <Grid item container p={1} pt={0}>
                                            {bookingList.length > 0?
                                            <Grid item container>
                                                <Grid item>
                                                    <TCLabel>Select Class Session Time</TCLabel>
                                                </Grid>
                                                <Grid item>
                                                    <TCLabel style={{color: 'red'}}>{joiForm.hours}</TCLabel>
                                                </Grid>
                                                <Grid xs={12}>
                                                {
                                                    bookingList.map(value => {
                                                        return(
                                                            <Card item xs={12} mb={1}>
                                                                <TCAccordion title={
                                                                    <TCLabel>
                                                                        <div>
                                                                            {moment(value.date).format("DD/MM/YYYY")}
                                                                            <div>{value.eventName}</div>
                                                                        </div>
                                                                    </TCLabel>
                                                                }>
                                                                    {
                                                                        value.courts.map(v => {
                                                                            return (
                                                                                <BookingScheduleList data={{
                                                                                    ...v, date: value.date
                                                                                }} court={form.court} date={form.date} 
                                                                                    allowMultipleCourt={true}
                                                                                    allowMultiple={false} 
                                                                                    disableSelectionClass={{
                                                                                        currentClass: currentClass,
                                                                                        court: v._id, 
                                                                                        date: value.date
                                                                                    }}
                                                                                    onChangeSchedule={(e) => {
                                                                                        console.log('here check e', e, v)
                                                                                        checkCourtInfo(e, v)
                                                                                }}/>
                                                                            )
                                                                        })    
                                                                    }
                                                                </TCAccordion>
                                                            </Card>
                                                        )
                                                })}
                                                </Grid>
                                            </Grid>
                                            :
                                            <Grid item>
                                                <TCLabel >No Available Court</TCLabel>
                                            </Grid>    
                                            }
                                            
                                        </Grid>
                                    }
                                    {
                                        (form.classType.value == typeOptions[1].value) &&
                                        <Grid item container xs={12} p={1}>
                                            <ClassScheduleInfo 
                                                setClassCourt={(e) => {
                                                    console.log('here setClassCourt', e)
                                                    setForm(prev => ({
                                                        ...prev,
                                                        ...e,
                                                        hours: convertHourstoArray({ startHours: e.classStartHours || null, endHours: e.classEndHours || null })
                                                    }))
                                                }}
                                            />
                                            <Grid container spacing={1}>
                                                <Grid item>
                                                    <Grid item container spacing={1}>
                                                        <Grid item>
                                                            <TCLabel style={{
                                                                color: 'rgb(133, 133, 133)'
                                                            }}>Bundle: Total Price (in IDR)</TCLabel>
                                                        </Grid>
                                                        <Grid item>
                                                            <TCLabel style={{color: 'red'}}>{joiForm.bundlePrice}</TCLabel>
                                                        </Grid>
                                                    </Grid>
                                                    <NumericFormat value={form.bundlePrice}
                                                        onValueChange={(values) => {
                                                            const {formattedValue, value, floatValue} = values;
                                                            // do something with floatValue
                                                            onChange({ bundlePrice: value.replace(/^0+/, '') })
                                                        }}
                                                        // disabled={form._id}
                                                        decimalScale={0}
                                                        allowLeadingZeros={false}
                                                        allowedDecimalSeparators={false}
                                                        allowNegative={false}
                                                        thousandSeparator
                                                        customInput={TCInput}/>
                                                </Grid>
                                                <Grid item>
                                                    <TCInput xs label={"Bundle: Total Session"}
                                                        onChange={(e) => {
                                                            if (!validation.isNumber(e.currentTarget.value)) { return; }
                                                            if (e.currentTarget.value == "0") { return; }
                                                            if (e.currentTarget.value > 100) { return; }
                                                            onChange({
                                                                bundleSession: e.currentTarget.value
                                                            })
                                                        }}
                                                        value={form.bundleSession}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Grid item container spacing={1}>
                                                        <Grid item>
                                                            {/* <TCLabel style={{
                                                                color: 'rgb(133, 133, 133)'
                                                            }}>Bundle: End Date</TCLabel> */}
                                                            {/* <TCInput type={"date"} onChange={(e) => {
                                                                onChange({
                                                                    bundleEndDate: e.currentTarget.value
                                                                })                                                                
                                                            }} value={form.bundleEndDate}/> */}
                                                            <DateRangeInput 
                                                                label={'Bundle: End Date'}
                                                                disabledStart={!form.startClassDate}
                                                                startDateMinDate={form.startClassDate}
                                                                startDateMaxDate={form.endClassDate}
                                                                placeholderStart={'End Date'}
                                                                disabledRange={true}
                                                                styles={{input:{padding:'9px'}}}
                                                                showLabel={true}
                                                                startDate={form.bundleEndDate}
                                                                onChangeStartDate={(e) => onChange({
                                                                    bundleEndDate: e
                                                                })}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <Grid item container spacing={1}>
                                    <Grid item>
                                        <TCLabel style={{
                                            color: 'rgb(133, 133, 133)'
                                        }}>Fee per Player (in IDR)</TCLabel>
                                    </Grid>
                                    <Grid item>
                                        <TCLabel style={{color: 'red'}}>{joiForm.fee}</TCLabel>
                                    </Grid>
                                </Grid>
                                <NumericFormat value={form.fee}
                                    onValueChange={(values) => {
                                        const {formattedValue, value, floatValue} = values;
                                        // do something with floatValue
                                        onChange({ fee: formattedValue.replace(/^0+/, ''), feeNominal: value.replace(/^0+/, '') })
                                    }}
                                    disabled={form._id}
                                    decimalScale={0}
                                    allowLeadingZeros={false}
                                    allowedDecimalSeparators={false}
                                    allowNegative={false}
                                    thousandSeparator
                                    customInput={TCInput}/>
                            </Grid>
                            <Grid item xs={12}>
                                <TCInput showAlert={joiForm.playersCapacity} type="tel" value={form.playersCapacity || ""} label={"Max Players"} 
                                disabled={form._id && !form.isAdmin}
                                onChange={(e) => { 
                                    console.log(e.currentTarget.value, !validation.isNumber(e.currentTarget.value), form.playersCapacity)
                                    if (!validation.isNumber(e.currentTarget.value)) { return; }
                                    if (Number(e.currentTarget.value) < 0) { return; }
                                    onChange({playersCapacity: !e.currentTarget.value ? null : Number(e.currentTarget.value)}) 
                                }}/>
                            </Grid>
                            <Grid item xs={12} >
                                <TCLabel style={{
                                    color: 'rgb(133, 133, 133)'
                                }}>Gender</TCLabel>
                                <Grid item container style={{marginTop: -8}} columnSpacing={1}>
                                    <Grid item>
                                        <FormControlLabel control={<Checkbox 
                                        disabled={form._id && !form.isAdmin}
                                        checked={form.gender.indexOf("male") >= 0 ? true : false} onChange={() => {
                                            let genders = form.gender
                                            if (genders.indexOf("male") >= 0) {
                                                onChange({gender: ["female"]})
                                            } else {
                                                onChange({gender: ["male", "female"]})
                                            }
                                        }} />} label={<TCLabel>Male</TCLabel>} />
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel control={<Checkbox 
                                        disabled={form._id && !form.isAdmin}
                                        checked={form.gender.indexOf("female") >= 0 ? true : false} onChange={() => {
                                            let genders = form.gender
                                            if (genders.indexOf("female") >= 0) {
                                                onChange({gender: ["male"]})
                                            } else {
                                                onChange({gender: ["male", "female"]})
                                            }
                                        }} />} label={<TCLabel>Female</TCLabel>} /> 
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} columnSpacing={1}>
                                <Grid item xs={6}>
                                    <TCInput showAlert={joiForm.minAge} type="tel" value={form.minAge || ""} label={"Min Age"} 
                                    disabled={form._id && !form.isAdmin}
                                    onChange={(e) => { 
                                        if (!validation.isNumber(e.currentTarget.value)) { return; }
                                        if (e.currentTarget.value.length > 2) { return; }
                                        onChange({minAge: !e.currentTarget.value ? null : Number(e.currentTarget.value)}) 
                                    }}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <TCInput showAlert={joiForm.maxAge} type="tel" value={form.maxAge || ""} label={"Max Age"} 
                                    disabled={form._id && !form.isAdmin}
                                    onChange={(e) => { 
                                        if (!validation.isNumber(e.currentTarget.value)) { return; }
                                        if (e.currentTarget.value.length > 2) { return; }
                                        onChange({maxAge: !e.currentTarget.value ? null : Number(e.currentTarget.value)}) 
                                    }}/>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TCLabel>User</TCLabel>
                                <AsyncSelect
                                    placeholder={'Select Coach / Assistant'}
                                    isClearable
                                    async
                                    defaultOptions
                                    loadOptions={loadUserOption}
                                    isMulti={true}
                                    value={form.coach} onChange={(e) => { onChange({ coach: e }) }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TCInput label={"Payment Method"} disabled={true} value={
                                    (form.isAdmin || !form._id) ? "STC" : "Direct to owner"
                                }/>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel control={<Checkbox 
                                    // disabled={form.hideUsername}
                                    checked={form.hideUsername} onChange={() => {
                                        onChange({
                                            hideUsername: !form.hideUsername
                                        })
                                    }} />} label={<TCLabel>Hide participant name</TCLabel>} /> 
                            </Grid>
                            <Grid item xs={12}>
                                <TCLabel style={{
                                    color: 'rgb(133, 133, 133)'
                                }}>Class Description</TCLabel>
                                <ReactQuill theme="snow" value={form.description}
                                    modules={{toolbar: toolbarOptions}}
                                    onChange={(e) => {
                                        onChange({description: e})
                                    }}
                                />
                                <Grid sx={{
                                    textAlign: 'right'
                                }}>
                                    {
                                        joiForm.description ?
                                        <TCLabel style={{fontSize: 10, color: 'red'}}>{`${removeTags(form.description)}/500`}</TCLabel>
                                        :
                                        <TCLabel style={{fontSize: 10, color: 'black'}}>{`${removeTags(form.description)}/500`}</TCLabel>
                                    }
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <TCLabel style={{
                                    color: 'rgb(133, 133, 133)'
                                }}>Active</TCLabel>
                                <Switch 
                                    sx={{ marginLeft: -1 }}
                                    checked={form.isActive} 
                                    onChange={() => onChange({isActive: !form.isActive})}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TCLabel style={{
                                    color: 'rgb(133, 133, 133)'
                                }}>Upload Picture</TCLabel>
                                <DropZoneComponent
                                    className="drop-zone"
                                    uploadName={"classImage"}
                                    file={form.classImage}
                                    isMultiple={false}
                                    onDrop={(files, event) => {
                                        if (files.length >= 4) { return; }
                                        let currImages = form.classImage ? form.classImage : []
                                        for (let i = 0; i < files.length; i++) {
                                            currImages = currImages.concat(files[i])
                                        }
                                        onChange({ classImage: currImages })
                                    }}
                                >
                                    <div className='dropzone mb-0' style={{
                                        textAlign: 'center',
                                        minHeight: '150px'
                                    }}>
                                    <div style={{ padding: 10, alignItems: 'center' }}>
                                        <label
                                            htmlFor={"classImage"}
                                            style={{ cursor: 'pointer' }}
                                            className='dropzone-child mb-0'>
                                            <i className='fa fa-upload mr-1' />
                                            Upload Class Image
                                        </label>
                                        {/* <div style={{marginTop: 10}}></div>
                                        <label>
                                            Use your professional photo
                                        </label> */}
                                        <div style={{ overflowX: 'auto' }}>
                                        {
                                            (form.classImage && form.classImage.length > 0) &&
                                            <div style={{ textAlign: 'left' }}>
                                            Uploaded file :
                                            {
                                                form.classImage.map((value, index) => {
                                                return (
                                                    <div key={`${value.name}${index}`} className='d-flex mb-1' style={{ textAlign: 'left' }}>
                                                    <img src={(!value.name && value.includes("https")) ? value : URL.createObjectURL(value)} style={{ minWidth: '100px', maxHeight: '50px', objectFit: 'contain' }}
                                                        onClick={() => {
                                                        }}
                                                    />
                                                    <label className='mt-1 mb-0 ml-1' style={{ alignItems: 'flex-end' }}>
                                                        {value.name}
                                                        <span style={{ marginLeft: 5, color: 'gray', cursor: 'pointer' }} onClick={() => {
                                                        deleteClassImage(index)
                                                        }}>X</span>
                                                    </label>
                                                    </div>
                                                )
                                                })
                                            }
                                            </div>
                                        }
                                        </div>
                                    </div>
                                    </div>
                                </DropZoneComponent>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} p={2} textAlign={"right"}>
                            {/* <Button sx={{fontSize: 10, p: 0, pl: 1, pr: 1}} color={"white"} 
                                onClick={() => {setOpenForm(false)}}>Cancel</Button> */}
                            <Button variant='contained' color={"primary"} 
                                disabled={disabledForm() || loading}
                                onClick={() => {submitClass()}}>{
                                    form._id ? "Update" : "Create"
                                }</Button>
                        </Grid>
                    </>        
                    </Box>
                </Card>
            </Grid>
            <Grid item container xs={12} md={4} lg={6}>
                <Card sx={{ p: 2, pl: 0, pr:0, width: '100%' }}>
                    <Box sx={{
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                        <>
                            <Grid container p={2} pt={0} rowSpacing={1} style={{overflow: 'auto'}}>
                                <Grid item xs={12}>
                                    <TCLabel>Participant ({form.totalParticipant || 0})</TCLabel>
                                </Grid>
                                {
                                    (participant.length <= 0) &&
                                    <Grid item xs={12}>
                                        <TCLabel>No Participant</TCLabel>
                                    </Grid>
                                }
                                <Grid container mt={2}>
                                    {/* Sort date time */}
                                    {
                                        form.classSchedule && form.classSchedule.dates.map(v => {
                                            return (
                                                <Grid item xs={12}>
                                                    <TCAccordion title={
                                                        <TCLabel>
                                                            <div>
                                                                {moment(v).format("DD/MM/YYYY")} <span style={{fontWeight: 'bold'}}>({countTotal(v, participant)})</span>
                                                            </div>
                                                        </TCLabel>
                                                    }>
                                                        {
                                                            participant.map(value => {
                                                                console.log('here info value', value)
                                                                if (value.date == v) { 
                                                                    return (
                                                                        <Grid item container xs={12} p={1} mb={1} alignItems={"center"} sx={{
                                                                            border: '1px solid rgb(133, 133, 133)', borderRadius: 2
                                                                        }}>
                                                                            <Grid item xs={1}>
                                                                                {
                                                                                    form.isAdmin ?
                                                                                    <Grid container alignItems={"center"} textAlign={"center"}>
                                                                                        <Grid item xs={12}>
                                                                                            <TCTitleLabel>{value.classObj.pax}</TCTitleLabel>
                                                                                        </Grid>
                                                                                        <Grid item xs={12}>
                                                                                            <TCLabel>Pax</TCLabel>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                    :
                                                                                    <Grid container alignItems={"center"} textAlign={"center"}>
                                                                                        <Grid item xs={12}>
                                                                                            <TCTitleLabel>{value.pax}</TCTitleLabel>
                                                                                        </Grid>
                                                                                        <Grid item xs={12}>
                                                                                            <TCLabel>Pax</TCLabel>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                }
                                                                            </Grid>
                                                                            <Grid item container xs>
                                                                                <Grid item>
                                                                                    <TCLabel>{value.user.name}</TCLabel>
                                                                                </Grid>
                                                                                {
                                                                                    form.isAdmin &&
                                                                                    <Grid item xs={12}>
                                                                                        <a href={`/orders/${value._id}`} className='span-link' style={{
                                                                                            color: '#364152'
                                                                                        }}><TCLabel>{value.bookingId}</TCLabel></a>
                                                                                    </Grid>
                                                                                }
                                                                            </Grid>
                                                                        </Grid>
                                                                    )   
                                                                }
                                                            })
                                                        }
                                                    </TCAccordion>
                                                </Grid>
                                            )
                                        })
                                    }
                                    {
                                        (!form.classSchedule 
                                            || (form.classSchedule && form.classSchedule.classType == "event")
                                        ) && participant.map(value => {
                                            return (
                                                <Grid item container xs={12} p={1} mb={1} alignItems={"center"} sx={{
                                                    border: '1px solid rgb(133, 133, 133)', borderRadius: 2
                                                }}>
                                                    <Grid item xs={1}>
                                                        {
                                                            form.isAdmin ?
                                                            <Grid container alignItems={"center"} textAlign={"center"}>
                                                                <Grid item xs={12}>
                                                                    <TCTitleLabel>{value.classObj.pax}</TCTitleLabel>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <TCLabel>Pax</TCLabel>
                                                                </Grid>
                                                            </Grid>
                                                            :
                                                            <Grid container alignItems={"center"} textAlign={"center"}>
                                                                <Grid item xs={12}>
                                                                    <TCTitleLabel>{value.pax}</TCTitleLabel>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <TCLabel>Pax</TCLabel>
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                    <Grid item container xs>
                                                        <Grid item>
                                                            <TCLabel>{value.user.name}</TCLabel>
                                                        </Grid>
                                                        {
                                                            form.isAdmin &&
                                                            <Grid item xs={12}>
                                                                <a href={`/orders/${value._id}`} className='span-link' style={{
                                                                    color: '#364152'
                                                                }}><TCLabel>{value.bookingId}</TCLabel></a>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </Grid>
                        </>
                    </Box>
                </Card>
            </Grid>
        </Grid>
    </>
  )
}

function ClassSession ({
    data
}) {

    const convertHours = (hours) => {
        let hoursEnd = Number(hours.split(":")[0])
        hoursEnd += 1
        hours = hoursEnd < 10 ? `0${hoursEnd}:00` : `${hoursEnd}:00`
        return hours
    }

    return (
        <Grid container mt={1} xs={12} sx={{
            border: '1px solid rgb(133, 133, 133)',
            borderRadius: 2,
            padding: 1
        }}>
            <Grid item xs={12}>
                <TCLabel style={{
                        color: 'rgb(133, 133, 133)'
                    }}>Class Owner : {
                        data.createdBy ?
                        <a href={`/users/detail/${data.createdBy._id}`} className='span-link' style={{
                            color: '#364152'
                        }}>
                            {data.isAdmin ? 'STC' : data.createdBy.name}
                        </a>
                        :
                        <a style={{
                            color: '#364152'
                        }}>
                            {data.isAdmin ? 'STC' : data.createdBy.name}
                        </a>
                    }
                </TCLabel>
            </Grid>
            <Grid container item xs={12} spacing={0.5}>
                <Grid item>
                    <TCLabel style={{
                        color: 'rgb(133, 133, 133)'
                    }}>Court : </TCLabel>
                </Grid>
                <Grid item>
                    {
                        data.classSchedule ?
                        <TCLabel>
                            {data.classSchedule.courtSelection == "fixed" && <TCLabel>{`${courtTypeObj[data.classSchedule.court.courtType]} ${data.classSchedule.court.name}`}</TCLabel>}
                            {data.classSchedule.courtSelection == "dynamic" && <TCLabel>{data.classSchedule.courtType.map((value, index) => { return `${courtTypeObj[value]}${index < data.classSchedule.courtType.length - 1 ? "," : ""} ` })}</TCLabel>}
                        </TCLabel>
                        :
                        data.courtsInfo ? 
                        <>
                            {data.courtsInfo.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).map(value => {
                                return <TCLabel>
                                    {`${courtTypeObj[value.courtType]} ${value.name}`}
                                </TCLabel>
                            })}
                        </>
                        :
                        <TCLabel>
                            {data.courtInfo ? `${courtTypeObj[data.courtInfo.courtType]} ${data.courtInfo.name}` : "-"}
                        </TCLabel>
                    }
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={0.5}>
                <Grid item>
                    <TCLabel style={{
                        color: 'rgb(133, 133, 133)'
                    }}>Date Time : </TCLabel>
                </Grid>
                <Grid item>
                    {
                        data.classSchedule ?
                        <TCLabel>{`${moment(data.classSchedule.dates[0]).format("DD MMM YYYY")} - ${moment(data.classSchedule.endClassDateString).format("DD MMM YYYY")} | `}</TCLabel>
                        :
                        <TCLabel>{moment(data.date).format("DD MMM YYYY (dddd)")}</TCLabel>
                    }
                </Grid>
                <Grid item>
                    <TCLabel>{`${data.hours[0]} - ${convertHours(data.hours[data.hours.length - 1])}`}</TCLabel>
                </Grid>
            </Grid>
        </Grid>
    )
}

const groupSession = (
    userSessions
) => {
    let newOrder = []
    for (let i = 0 ; i < userSessions.length; i ++) {
        if (newOrder.length == 0) {
            newOrder.push({
                date: userSessions[i].date,
                courts: [{
                    ...userSessions[i].court,
                    hours: [ ...userSessions[i].hours ]
                }],
            })
        } else {
            let similiarDate = false
            for (let j = 0; j < newOrder.length; j ++) {
                console.log(newOrder[j])
                if (newOrder[j].date === userSessions[i].date) {
                    similiarDate = true
                    let courts = newOrder[j].courts
                    let similiarCourts = false
                    for (let k = 0; k < courts.length; k++) {
                        console.log('here new order - check null', courts[k], userSessions[i])
                        console.log('here new order - check null', courts[k]._id, userSessions[i].court._id)
                        if (courts[k]._id.toString() == userSessions[i].court._id.toString()){
                            courts[k].hours = courts[k].hours.concat(userSessions[i].hours)
                            courts[k].hours = bubbleSort(courts[k].hours)
                            similiarCourts = true
                        }
                    }
                    if (!similiarCourts) {
                        newOrder[j].courts.push({
                            ...userSessions[i].court,
                            hours: [ ...userSessions[i].hours ]
                        })
                    }
                }
            }
            if (!similiarDate) {
                newOrder.push({
                    date: userSessions[i].date,
                    courts: [{
                        ...userSessions[i].court,
                        hours: [ ...userSessions[i].hours ]
                    }],
                })
            }
        }
        // sort date
    }
    newOrder.sort((a,b) => (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0))
    return newOrder
}

function convertHourstoArray ({
    startHours, endHours
}) {
    let hours = []

    if (!startHours || !endHours) {
        return hours
    }
    
    let startH = Number(startHours.split(":")[0])
    let endH = Number(endHours.split(":")[0])
    for (let i = startH; i < endH ; i++) {
        hours.push(`${i < 10 ? 0 : ''}${i}:00`)
    }
    return hours
}

function adjustDate(dateString) {
    const date = new Date(dateString);
  
    date.setMilliseconds(0);

    if (date.getSeconds() !== 0) {
        date.setSeconds(0);
        date.setMinutes(date.getMinutes() + 1);
        date.setDate(date.getDate()-1)
    }
    return date.toISOString();
}

function getDates(startDate, stopDate, offDays) {
    var dateArray = [];
    // var currentDate = moment(adjustDate(startDate));
    var currentDate = moment(startDate).set({hour:0,minute:0,second:0,millisecond:0});
    var stopDate = moment(stopDate);
    while (currentDate <= stopDate) {
        if (offDays && offDays.length > 0) { 
            if (offDays.indexOf(moment(currentDate).format('ddd')) < 0) {
                dateArray.push( moment(currentDate).format('YYYY-MM-DD') )
            }
        } else {
            dateArray.push( moment(currentDate).format('YYYY-MM-DD') )
        }
        currentDate = moment(currentDate).add(1, 'days');
    }
    return dateArray;
}

function bubbleSort(arr) {
    var i, j;
    var len = arr.length;
    var isSwapped = false;
    for (i = 0; i < len; i++) {
        isSwapped = false;
        for (j = 0; j < (len - i - 1); j++) {
            if (arr[j] > arr[j + 1]) {
                var temp = arr[j]
                arr[j] = arr[j + 1];
                arr[j + 1] = temp;
                isSwapped = true;
            }
        }
        if (!isSwapped) {
            break;
        }
    }
    return arr
}

export default AddClass;

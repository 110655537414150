import React, { useState } from 'react';

import PropTypes from 'prop-types';

// material-ui
import { Box, Card, Grid, Typography, Avatar, Button } from '@mui/material';

import { currencyFormat } from 'helper/NumberFormat';

// project imports
import SubCard from 'ui-component/cards/SubCard';
import MainCard from 'ui-component/cards/MainCard';
import SecondaryAction from 'ui-component/cards/CardSecondaryAction';
import { gridSpacing } from 'store/constant';

import { getPackages } from 'services/packageServices';
import { useEffect } from 'react';
import { TCLabel } from 'components/TopCourtComponents/Label/TopCourtLabel';
import TableComponent from 'components/TopCourtComponents/Table/TableComponent';
import moment from 'moment';
import PackageFilter from './packageFilter';
import { TypeSelectOption } from 'components/TopCourtComponents/Input/NominalRangeFilter';

const initFilter = {
    name: '',
    sessionType: TypeSelectOption[0],
    sessionStart: '',
    sessionEnd: '',
    priceType: TypeSelectOption[0],
    priceStart: '',
    priceEnd: ''
}

export const courtTypeObj = {
    all_court: "All Court",
    indoor: "Indoor",
    outdoor: "Outdoor",
    hitting_room: "Hitting"
}

export default function PackageList({

}) {

    const [rows, setRows] = useState([])
    const [filter, setFilter] = useState(initFilter)

    useEffect(() => {
        loadData()
    }, [filter])

    const loadData = async () => {
        let { data, error } = await getPackages({
            name: filter.name? filter.name : '',
            priceType:filter.priceType? filter.priceType.value : '',
            priceStart:filter.priceStart? filter.priceStart : '',
            priceEnd: filter.priceEnd? filter.priceEnd : '',
            sessionType:filter.sessionType? filter.sessionType.value : '',
            sessionStart:filter.sessionStart? filter.sessionStart : '',
            sessionEnd: filter.sessionEnd? filter.sessionEnd : '',
        });
        console.log(data)
        setRows(data)
    }

    const valueCustomator = (row) => {
        return ({
            createdDate: <TCLabel>{moment(row.createdDate).format("YYYY/MM/DD HH:mm:ss")}</TCLabel>,
            name: <span className='span-link' onClick={() => {window.location.href = `/packages/edit/${row._id}`}}>{row.name}</span>,
            sessions: <TCLabel>{`${courtTypeObj[row.court]} ${row.totalSession}`}</TCLabel>,
            price: <TCLabel>{currencyFormat(row.price)}</TCLabel>,
            advancedMonth: <TCLabel>Month + {row.advancedMonths}</TCLabel>,
            sessionValidity: <TCLabel>{row.validity} Month</TCLabel>,
            limitGlobal: <TCLabel>{row.maxPackages ? `${row.usage ? row.usage : 0}/${row.maxPackages}` : "-"}</TCLabel>,
        })
    }

    const onChangeFilter = (e) => {
        setFilter(prev => ({
            ...prev,
            ...e
        }))
    }

    const clearFilter = () => {
        setFilter(initFilter)
    }


    return (
        <>
            <Button sx={{ mb: 2 }} variant="contained" disableElevation onClick={() => {
                window.location.href = "/packages/add"
            }}>Add New Package</Button>
            <PackageFilter filter={filter} onChangeFilter={onChangeFilter} clearFilter={clearFilter} />
            <TableComponent
                columns={[
                    { label: "Created Date" },
                    { label: "Package Name" },
                    { label: "Session" },
                    { label: "Price" },
                    { label: "Advanced Booking" },
                    { label: "Session Validity" },
                    { label: "Limit Global / Month" },
                ]}
                rows={rows}
                valueCustomator={valueCustomator}
            />
        </>
    )
}